.home-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
}

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
}
.text-logo {
  margin-left: 5%;
  margin-right: 5%;
  position: fixed;
  top: 10%;
  max-height: 25vh;
  max-width: 100%;
  z-index: 2;
}

/* .accessory-colors-btn {
  background-color: blue;
  color: white;
  border: none;
  border-radius: 10px;
} */

.accessory-colors-btn-container {
  align-items: center;
  text-align: center;
}

.accessory-colors-btn:hover {
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
  color: #efefef;
  background: rgb(0, 74, 134);
}

.accessory-colors-btn {
  text-align: center;
  text-decoration: none !important;

  width: 50%;

  display: flex;
  margin: auto;
  /* margin: 5vh 0vh; */
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: white;
  background: rgb(15, 134, 231);
  /* background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  ); */
  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.home-image {
  text-align: center;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 15px;
  width: 100%;
}
.home-header {
  color: #e1e1e1 !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.9rem;
  text-align: center;
  margin-top: 10px;
}

.info {
  margin: 0px 20px;
  padding-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.home-text {
  position: relative;
  top: 40vh;
  text-align: center;
  font-family: "Khula", sans-serif;
  font-size: 1.9rem;
}

.home-description {
  display: flex;
  margin: 0px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 41vh;
}

/* START BRADY BOOTSTRAP STUFF */

.about-description {
  padding-right: 5%;
  padding-left: 5%;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
}

.about-job-header {
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
}

.category-img-container {
  text-align: center;
  height: 50vh;
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.category-img {
  display: inline-block;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.about-photo {
  display: inline-block;
  width: 150px;
  height: 150px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.services-container {
  margin-top: 30px;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .home-image {
    width: 50%;
  }

  .contact-button {
    width: 50px;
  }

  .slogan {
    top: 26vh;
  }

  .slogan-text {
    font-size: 4vw;
  }

  .category-img {
    display: inline-block;
    height: 50vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
