.footer-container {
  width: 100% !important;
  /* position: absolute;
  bottom: 0; */
  /* margin: 0 !important; */
  height: 21vh;
  /* background-color: #eeeeee; */
  text-align: center;
  background-color: #1e1e1e !important;
}
.footer-wrapper {
  padding-top: 20px;
}

.footer-text {
  font-weight: 100;
  font-size: small;
  text-align: center;
}

.footer-links {
  text-align: right;
}
.footer-link {
  text-decoration: none;
  color: darkgray;
  font-size: small;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.powered-text {
  /* padding-bottom: 50px; */
}

.footer-links-title {
  margin: 0;
}

.footer-company-name {
  display: inline;
}

.footer-company-icon {
  display: inline;
  width: 20px;
  margin-right: 5px;
}

.footer-icons {
  text-align: center;
  padding-top: 2vh;
  font-size: x-large;
}

.footer-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: #efefef;

  padding-bottom: 10px;
}

.footer-icon:hover {
  color: lightblue;
}
