.carousel-header {
  padding-top: 50px;
}

.carousel-item {
  height: 65vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.slogan {
  color: whitesmoke;
  text-shadow: 1.5px 1.5px 1.5px rgb(114, 114, 114);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.9rem;
  z-index: 2;
  position: absolute;
  text-align: center;
  top: 26vh;
  width: 100%;
}

.slogan-text {
  font-size: xx-large;
}

.z-4 {
  z-index: 4;
}

.z-2 {
  z-index: 2;
}

.item-1 {
  background-image: url("https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Home/carousel-1.jpg");
}

.item-2 {
  background-image: url("https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Home/carousel-2.jpg");
}
.item-3 {
  background-image: url("https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Home/carousel-3.jpg");
}

@media only screen and (min-width: 600px) {
  .item-1 {
    background-image: url("https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Home/carousel-1-laptop.jpg");
  }

  .item-2 {
    background-image: url("https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Home/carousel-2-laptop.jpg");
  }
  .item-3 {
    background-image: url("https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Home/carousel-3-laptop.jpg");
  }

  .slogan-text {
    font-size: 60px;
  }
}
