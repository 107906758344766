.services-container {
  /* text-align: center; */
}

.services-list-item {
  /* background-color: #1e1e1e; */
  text-align: center;
  /* border-radius: 7pt; */
  padding: 5px;

  /* margin: 5px; */

  /* margin-left: 5px;
  margin-right: 5px; */
}

.content-wrapper {
  /* position: absolute; */
  margin-top: 0;
  padding-top: 1vh !important;
  padding-bottom: 5vh;
}

.row {
  /* align-self: center !important; */
  align-items: center !important;
  /* text-align: center !important; */
}

.services-card {
  /* margin: 5px; */
  display: inline-block;
}

.services-icon {
  display: inline-block;
  width: 40px;
}

.services-list {
  /* max-width: 95%; */
  /* padding-left: 5%; */
}

/* .col-container {
  width: 100%;
} */

.services-list-container {
}
/* .services-list {
  text-align: center;
  margin-left: 15%;
} */

.services-description {
  /*width: 50%;*/
  /* padding-right: 5%; */
  /* padding-left: 5%; */
  /* color: rgb(63, 63, 63); */
  font-family: "Montserrat", sans-serif;
  font-size: medium;
}

.services-header {
  /* margin-top: 57px; */
  text-align: center;
}

.services-list-items-container {
  text-align: left;
}

.faq-question {
  font-weight: 700;
  font-size: 1.4rem;
}

.services-img {
  max-width: 100%;
  /* margin: 3vh 0vh; */
  justify-content: center;
}

/* laptop screen */
@media only screen and (min-width: 600px) {
  .services-img {
    max-width: 75%;
  }
}
