.measurements-container {
  position: relative;
  min-height: 100vh;
  /* text-align: center; */
}

.measurements-header {
  margin-top: 57px;
  text-align: left !important;
}

.success-alert {
  background-color: #43a047;

  color: white;
}

.sending-alert {
  background-color: #42a5f5;
  color: white;
}

.sending-alert-text {
  /* display: inline-block; */
  position: absolute;
  top: 1vh;
  left: 34%;
}

.alert {
  margin-top: 5vh;
  height: 5vh;
  text-align: center;
  padding: 5px;
  /* padding: 5px;
    padding-top: 10px; */
  /* padding-bottom: 30px; */
  /* padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 45px; */
  /* padding-top: 1vh; */
}

.fail-alert {
  background-color: #e53935;

  color: white;
}

.success-alert-text {
  font-size: small;
  text-align: left;
}

.contact-img {
  top: 40vh;
}

.contact-details {
  font-family: "Montserrat", sans-serif;
}

/* START BUTTON */

.contact-button {
  display: flex;
  margin: auto;
  margin-top: 5vh;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 25px;
  padding: 15px;
  color: white;
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  border: none;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .form-container {
    width: 500px;
    margin: auto;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }
}
