/* .gallery-img-container {
  height: 50vh;
  overflow: hidden;
  text-align: center;
  align-items: center;
  border-radius: 30px;
}

.gallery-img {
  display: inline-block;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 15px;
  border-radius: 30px;

} */

.gallery-img-container {
  height: 50vh;
  overflow: hidden;
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* border-radius: 20px; */
  margin: 10px;
}

.gallery-img {
  width: 100%; /* Make the image take the full width of the container */
  height: 100%; /* Make the image take the full height of the container */
  object-fit: cover; /* Cover the container without distortion */
  border-radius: 15px;
}

.img-title {
  height: 100px;
  text-align: center;
}

.gallery-container {
  margin-top: 57px;
}

.gallery-header {
  margin-top: 57px;
  text-align: left !important;
}

/* .contact-container {
  position: relative;
  min-height: 100vh;
} */
